import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MetaPixel = () => {
  const location = useLocation();

  useEffect(() => {
    const initFbq = () => {
      initFacebookPixelCode();
      window.fbq('init', '344593518639258');
    };

    const initFacebookPixelCode = () => {
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    };

    const trackPageView = () => {
      if (typeof window.fbq === 'function') {
        window.fbq('track', 'PageView');
      }
    };

    if (!window.fbq) {
      initFbq();
    } else {
      trackPageView();
    }

    // if (!window.fbq) {
    //   initFbq();
    // }

    // fbq('track', 'PageView');
  }, [location]);

  return (
    <Helmet>
      <noscript>{`
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=344593518639258&ev=PageView&noscript=1"
          alt=""
        />`}
      </noscript>
    </Helmet>
  );
};

export default MetaPixel;
