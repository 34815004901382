import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

/* PAGES */
import HomePage from './pages/home';
import NewsPage from './pages/news';
import NewDetails from './pages/news-details';
import About from './pages/about';
import HowTo from './pages/how-to';
import Faq from './pages/faq';
import Contact from './pages/contact';
import InvestmentFund from './pages/investment-fund';
import Fund from './pages/fund';
import Memories from './pages/memories';
import Corporative from './pages/corporative';
import Complaint from './pages/complaint';
import WorkWithUs from './pages/work-with-us';
import MetaPixel from './components/external/meta/pixel';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`
  })
});

function App() {
  return (
    <>
      <Router>
          <MetaPixel />
          <ApolloProvider client={client}>
            <Routes>
              <Route exact path="/" element={<HomePage/>} />
              <Route path="/news" element={<NewsPage/>} />
              <Route exact path="/news-details/:id" element={<NewDetails/>} />
              <Route path="/about" element={<About/>} />
              <Route path="/how-to" element={<HowTo/>} />
              <Route path="/faq" element={<Faq/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route path="/investment-fund" element={<InvestmentFund/>} />
              <Route path="/fund/:id" element={<Fund/>} />
              <Route path="/memories" element={<Memories/>} />
              <Route path="/corporative/:id" element={<Corporative/>} />
              <Route path="/complaint" element={<Complaint/>} />
              <Route path="/work-with-us" element={<WorkWithUs/>} />
            </Routes>
          </ApolloProvider>
      </Router>
    </>
  );
}

export default App;
